<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/countryside-banner.jpg">
			<div class="words">
				<p>
					<span>环保-油烟分离</span>
					软件定制化服务提供商
				</p>
			</div>
		</div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>环保</span>
					<p class="crumbs"><a>您的位置：</a> <a>产品</a>> <a>油烟分离</a> </p>
				</div>


				<div class="content">
					<img src="@/assets/images/products/OilFumeSeparation/1.png" alt="">
					<img src="@/assets/images/products/OilFumeSeparation/2.png" alt="">
					<img src="@/assets/images/products/OilFumeSeparation/3.png" alt="">
					<img src="@/assets/images/products/OilFumeSeparation/4.png" alt="">
					<img src="@/assets/images/products/OilFumeSeparation/5.png" alt="">
					<img src="@/assets/images/products/OilFumeSeparation/6.png" alt="">
					<img src="@/assets/images/products/OilFumeSeparation/7.png" alt="">
				</div>
				<div class="productsChilder">
					<h3 class="productsChilder-title">西藏奥利哲欧高新环保科技有限公司</h3>
					<div class="content">

						<p class="indent">
							专业从事餐饮油烟、工业废气净化环保设备的研发设计和生产，
							与浙江大学环境研究所共同科研合作，
							研制出适合中国国情的新一代“奥利哲欧”光电复合油烟净化器。
							颠覆传统净化工艺，采用全新物理净化加光化学反应，
							确保餐饮排放的油烟、颗粒物、非甲烷总烃3项指标全部达标。
							极大减少集烟罩、排烟管、风机内结油现象，
							避免因餐饮油烟引起火灾而造成的损失，解决油烟扰民等社会问题，
							是餐饮油烟、废气净化行业的一次技术革命。
						</p>
						<p class="indent">
							我们为客户提供油烟净化器和厨房设备设计制造，
							提供前期测量、定制改造方案、设备选型、现场安装及售后维护等服务，
							为各级机关、企事业单位、商超及餐饮油烟、废气净化改造
							提供专业性、定制化、一站式的全维设计和解决方案。

						</p>
					</div>
				</div>
				<div class="productsChilder">
					<h3 class="productsChilder-title">成功案例</h3>
					<div class="content">
						<ul class="caseList">
							<li v-for="item in caseList" :key="item.src">
								<div class="imgbox">
									<img :src="item.src">
								</div>
								<a class="text">
									{{item.text}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"

export default ({
	components: {
		MenuLayout,

	},
	data() {
		return {
			caseList: [
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/1.jpg'),
					text: "拉萨市市委机关第一食堂-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/2.jpg'),
					text: "拉萨市市委机关第一食堂-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/3.jpg'),
					text: "拉萨市市委机关第一食堂-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/4.jpg'),
					text: "拉萨市市委机关第一食堂-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/5.jpg'),
					text: "拉萨市市委机关第一食堂-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/6.jpg'),
					text: "拉萨市市委机关第一食堂-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/7.jpg'),
					text: "拉萨市市委机关第二食堂-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/8.jpg'),
					text: "拉萨市市委机关第二食堂-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/9.jpg'),
					text: "拉萨市市委机关第二食堂-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/10.jpg'),
					text: "拉萨市市委机关第二食堂-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/11.jpg'),
					text: "拉萨市市委机关第二食堂-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/12.jpg'),
					text: "拉萨市市委机关第二食堂-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/13.jpg'),
					text: "日喀则儿童福利二院-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/14.jpg'),
					text: "日喀则儿童福利二院-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/15.jpg'),
					text: "日喀则儿童福利二院-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/16.jpg'),
					text: "日喀则儿童福利二院-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/17.jpg'),
					text: "青海省生态环境厅-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/18.jpg'),
					text: "青海省生态环境厅-整改后"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/19.jpg'),
					text: "青海省生态环境厅-整改前"
				},
				{
					type: "环保-油烟分离",
					src: require('@/assets/images/case/temperature/20.jpg'),
					text: "青海省生态环境厅-整改后"
				}
			]
		}
	},
	methods: {

	}
});
</script>
